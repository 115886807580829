import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
<path d="M2345 4204 c-227 -33 -303 -53 -468 -120 -422 -172 -753 -520 -911
-957 -30 -81 -36 -90 -83 -122 -62 -41 -105 -93 -138 -162 -29 -59 -32 -105
-9 -123 23 -20 42 5 54 69 13 71 55 145 109 192 40 36 49 39 102 39 36 0 59 4
59 11 0 6 18 56 40 113 139 356 440 666 795 820 438 190 960 160 1363 -79 233
-137 423 -330 557 -563 55 -94 118 -244 107 -253 -4 -4 -25 -10 -48 -14 l-40
-6 -38 86 c-249 574 -838 916 -1456 846 -387 -45 -745 -253 -979 -569 -50 -69
-141 -234 -186 -340 -7 -16 -32 -33 -77 -52 -129 -54 -221 -145 -253 -255 -25
-81 -17 -150 17 -161 31 -10 58 22 58 71 0 73 28 135 89 196 90 89 208 127
476 150 151 13 607 7 835 -11 791 -62 1218 -65 1503 -10 56 11 135 20 175 20
69 0 73 1 67 20 -5 17 3 24 52 45 89 40 146 76 136 86 -6 6 -39 -4 -84 -25
-121 -56 -121 -56 -143 6 -10 29 -40 96 -66 149 -164 328 -402 567 -724 729
-244 123 -425 168 -691 175 -93 2 -183 1 -200 -1z m403 -279 c404 -66 790
-356 978 -735 20 -41 42 -91 50 -112 l13 -37 -57 -8 c-31 -4 -237 -8 -457 -7
-343 0 -451 4 -755 28 -540 43 -1129 47 -1278 9 l-23 -5 21 51 c112 286 371
558 665 701 113 55 275 106 380 119 50 6 104 13 120 15 51 7 249 -4 343 -19z"/>
<path d="M4110 3003 c-104 -46 -433 -103 -590 -103 -56 0 -67 -14 -19 -24 81
-16 268 3 399 40 107 31 211 70 236 89 24 18 18 18 -26 -2z"/>
<path d="M1725 2951 c-41 -18 -72 -47 -62 -57 3 -3 16 1 29 10 12 9 42 16 65
16 69 0 132 -68 161 -173 10 -35 9 -38 -6 -32 -62 25 -129 13 -183 -32 -92
-78 -106 -271 -27 -368 107 -129 303 -81 353 87 18 62 20 233 4 310 -27 126
-95 217 -182 243 -66 20 -103 19 -152 -4z m184 -297 c19 -24 21 -40 21 -153 0
-103 -4 -134 -20 -168 -22 -49 -54 -57 -85 -23 -43 48 -43 302 0 350 25 28 60
25 84 -6z"/>
<path d="M1125 2927 c-101 -34 -154 -86 -162 -160 -8 -81 63 -141 128 -106 31
16 39 28 39 60 0 41 -22 54 -59 35 -27 -14 -31 -14 -45 0 -19 18 -20 34 -4 69
26 58 142 94 187 58 l24 -19 -17 -75 c-9 -41 -31 -126 -49 -189 l-32 -115 -63
-3 c-54 -3 -62 -6 -62 -22 0 -18 7 -20 56 -20 53 0 55 -1 49 -22 -4 -13 -21
-50 -38 -82 -51 -96 -124 -124 -175 -66 -15 17 -32 30 -39 30 -21 0 -28 -49
-10 -68 22 -21 127 -29 167 -12 45 19 107 95 131 161 l22 59 88 0 88 0 26 -85
c21 -71 30 -87 55 -100 39 -20 86 -19 138 5 49 22 55 38 13 32 -29 -4 -30 -2
-50 63 -12 37 -21 71 -21 76 0 5 18 9 40 9 33 0 40 3 40 20 0 17 -7 20 -48 20
l-48 0 -48 168 c-62 214 -76 246 -118 268 -42 22 -151 28 -203 11z m178 -322
l34 -120 -69 -3 c-39 -2 -72 -1 -75 1 -2 3 11 61 29 130 18 70 36 123 39 119
4 -4 23 -61 42 -127z"/>
<path d="M2133 2710 c-47 -21 -58 -40 -19 -33 29 6 30 4 51 -135 27 -169 65
-256 125 -287 80 -41 153 14 199 152 41 123 49 307 14 320 -8 3 -25 1 -38 -5
-24 -11 -24 -11 -8 -58 17 -50 14 -166 -6 -235 -12 -40 -45 -79 -67 -79 -24 0
-52 78 -74 204 -11 65 -24 126 -30 136 -23 44 -79 51 -147 20z"/>
<path d="M2770 2721 c-77 -24 -148 -90 -168 -160 -18 -57 -14 -150 7 -196 61
-135 239 -164 353 -57 38 35 25 46 -20 16 -80 -55 -171 -5 -206 113 -6 18 6
29 96 83 112 68 127 82 127 120 0 65 -100 108 -189 81z m60 -56 c6 -8 14 -32
17 -54 5 -37 3 -41 -33 -64 -22 -13 -50 -30 -63 -37 l-24 -12 5 59 c7 77 31
123 62 123 13 0 29 -7 36 -15z"/>
<path d="M3043 2710 c-49 -22 -55 -36 -15 -35 l27 0 3 -200 c2 -190 3 -201 23
-217 26 -22 67 -23 97 -2 21 15 22 21 22 177 l0 162 33 23 c40 27 92 29 106 5
13 -24 22 -13 28 34 4 30 1 42 -16 57 -28 26 -67 14 -112 -35 l-37 -40 -7 30
c-13 60 -74 77 -152 41z"/>
<path d="M3475 2716 c-48 -21 -70 -53 -70 -100 0 -46 18 -78 100 -174 60 -70
66 -98 30 -130 -23 -19 -36 -23 -63 -18 -19 3 -42 12 -52 21 -31 28 -45 16
-15 -15 40 -42 104 -64 162 -56 56 7 87 26 113 69 41 66 20 125 -80 225 -37
37 -70 77 -74 89 -11 36 10 63 48 63 41 0 66 -23 41 -38 -26 -14 -14 -46 18
-50 21 -3 29 2 38 22 33 71 -101 134 -196 92z"/>
<path d="M3834 2716 c-17 -7 -40 -26 -52 -41 -43 -54 -23 -115 72 -223 67 -76
75 -110 34 -144 -27 -24 -66 -23 -103 2 -38 25 -49 25 -36 1 15 -26 108 -71
148 -71 38 0 98 23 125 47 10 9 23 34 29 55 16 61 -3 101 -93 195 -78 82 -93
114 -66 141 15 15 74 16 83 1 4 -5 -1 -19 -10 -29 -26 -28 -12 -52 27 -48 31
3 33 5 33 42 0 30 -6 43 -26 58 -33 25 -124 33 -165 14z"/>
<path d="M4083 2378 c-6 -7 -13 -37 -16 -65 -8 -73 -37 -134 -88 -183 -88 -85
-205 -121 -461 -141 -189 -15 -520 -2 -910 36 -139 14 -323 32 -408 40 -542
52 -957 41 -1215 -31 -95 -26 -238 -88 -257 -111 -21 -26 2 -26 59 -1 128 57
281 91 484 109 193 17 613 6 939 -25 146 -14 360 -34 475 -46 516 -49 989 -49
1152 1 137 42 239 125 289 234 29 63 36 153 15 179 -16 19 -45 21 -58 4z"/>
<path d="M4216 2265 c-8 -8 -17 -34 -21 -57 -17 -98 -93 -196 -191 -244 -30
-14 -54 -28 -54 -29 0 -2 17 1 38 7 63 18 132 57 179 104 53 53 108 163 99
198 -7 30 -31 40 -50 21z"/>
<path d="M1230 2184 c-128 -20 -269 -57 -358 -95 -69 -30 -45 -31 43 -3 114
36 281 62 449 70 139 7 173 14 124 28 -24 6 -215 6 -258 0z"/>
<path d="M1065 1813 c214 -403 577 -689 1025 -808 294 -78 625 -67 925 31 187
62 405 187 561 322 137 119 292 318 373 480 l31 62 -64 0 -64 0 -43 -72 c-71
-122 -147 -218 -255 -325 -311 -307 -748 -464 -1182 -424 -499 45 -916 310
-1181 749 l-43 72 -65 0 -65 0 47 -87z"/>
<path d="M1270 1894 c0 -17 117 -182 178 -251 77 -87 223 -209 319 -267 310
-185 666 -246 1013 -175 368 75 679 283 891 597 63 92 67 102 40 102 -13 0
-30 -15 -48 -42 -199 -311 -497 -526 -843 -607 -497 -118 -1017 50 -1352 436
-37 42 -86 107 -110 145 -32 50 -49 68 -65 68 -13 0 -23 -3 -23 -6z"/>
<path d="M2472 1705 c-20 -42 -24 -45 -72 -51 -27 -4 -50 -9 -50 -12 0 -4 16
-22 35 -42 34 -35 35 -37 26 -85 -5 -28 -8 -51 -6 -53 2 -2 24 7 51 21 l47 24
44 -25 c24 -13 46 -22 49 -20 2 3 0 27 -5 54 -10 49 -9 49 32 92 40 41 41 42
15 42 -66 0 -89 12 -111 56 -11 24 -24 44 -27 44 -3 0 -16 -20 -28 -45z"/>
<path d="M2150 1595 c-6 -16 -12 -35 -15 -42 -2 -7 -20 -15 -39 -19 -20 -3
-36 -9 -36 -13 0 -4 14 -17 30 -29 25 -19 30 -30 30 -62 0 -22 2 -40 5 -40 3
0 19 10 34 21 26 19 33 20 66 9 20 -7 38 -10 40 -8 3 2 -1 20 -7 40 -9 32 -8
40 11 63 29 38 27 45 -16 45 -31 0 -43 6 -65 32 l-27 33 -11 -30z"/>
<path d="M2814 1591 c-16 -27 -24 -31 -61 -31 -24 0 -43 -3 -43 -6 0 -3 10
-19 21 -36 19 -26 20 -35 11 -66 -6 -20 -10 -38 -7 -40 2 -2 20 1 40 8 33 11
40 10 66 -9 15 -11 31 -21 34 -21 3 0 5 18 5 39 0 33 5 43 30 61 17 12 30 25
30 29 0 4 -17 12 -37 17 -28 7 -39 16 -47 41 -16 46 -22 48 -42 14z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
